<template>
  <div class="content">
    <div class="zhanwu">暂无数据</div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 870px;
  padding-bottom: 60px;
}
</style>